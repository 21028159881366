export default function Checkbox({ className = '', ...props }) {
    return (
        <input
            {...props}
            type="checkbox"
            className={
                'rounded h-[25px] w-[25px] border-custom-gray border-2 bg-transparent checked:border-custom-gray checked:hover:border-custom-gray text-dark-blue shadow-sm focus:ring-dark-blue transition-all ease-in-out duration-300 focus:border-transparent focus:ring-yellow ' +
                className
            }
        />
    );
}
